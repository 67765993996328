body {
  height: 100%;
  overflow: hidden;
  background: radial-gradient(circle at bottom, navy 0, black 100%);
  width: 100%;
}
.space {
  background: rgba(128, 0, 128, 0.1) center/ 200px 200px round;
  border: 0.2px dotted navy;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.stars1 {
  animation: space 180s ease-in-out infinite;
  background-image: radial-gradient(
      1px 1px at 25px 5px,
      white,
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(1px 1px at 50px 25px, white, rgba(255, 255, 255, 0)),
    radial-gradient(1px 1px at 125px 20px, white, rgba(255, 255, 255, 0)),
    radial-gradient(1.5px 1.5px at 50px 75px, white, rgba(255, 255, 255, 0)),
    radial-gradient(2px 2px at 15px 125px, white, rgba(255, 255, 255, 0)),
    radial-gradient(2.5px 2.5px at 110px 80px, white, rgba(255, 255, 255, 0));
}

.stars2 {
  animation: space 240s ease-in-out infinite;
  background-image: radial-gradient(
      1px 1px at 75px 125px,
      white,
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(1px 1px at 100px 75px, white, rgba(172, 66, 66, 0)),
    radial-gradient(1.5px 1.5px at 199px 100px, white, rgba(255, 255, 255, 0)),
    radial-gradient(2px 2px at 20px 50px, white, rgba(255, 255, 255, 0)),
    radial-gradient(2.5px 2.5px at 100px 5px, white, rgba(255, 255, 255, 0)),
    radial-gradient(2.5px 2.5px at 5px 5px, white, rgba(255, 255, 255, 0));
}

.stars3 {
  animation: space 300s ease-in-out infinite;
  background-image: radial-gradient(
      1px 1px at 10px 10px,
      white,
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(1px 1px at 150px 150px, white, rgba(172, 66, 66, 0)),
    radial-gradient(1.5px 1.5px at 60px 170px, white, rgba(255, 255, 255, 0)),
    radial-gradient(1.5px 1.5px at 175px 180px, white, rgba(255, 255, 255, 0)),
    radial-gradient(2px 2px at 195px 95px, white, rgba(255, 255, 255, 0)),
    radial-gradient(2.5px 2.5px at 95px 145px, white, rgba(255, 255, 255, 0));
}

.star-wars {
  /* Flexbox to center the entire element on the screen */
  display: flex;
  justify-content: center;
  /* This is a magic number based on the context in which this snippet is used and effects the perspective */
  height: 800px;
  /* This sets allows us to transform the text on a 3D plane, and is somewhat a magic number */
  perspective: 400px;
  /* The rest is totally up to personal styling preferences */
  color:#d1d1d1;
  font-family: "Pathway Gothic One", sans-serif;
  font-size: 500%;
  font-weight: 600;
  letter-spacing: 6px;
  line-height: 150%;
  text-align: justify;
}

.fade {
  position: relative;
  width: 100%;
  min-height: 60vh;
  top: -25px;
  background-image: linear-gradient(0deg, transparent, black 75%);
  z-index: 1;
}

.crawl {
  /* Position the element so we can adjust the top property in the animation */
  position: relative;
  /* Defines the skew origin at the very center when we apply transforms on the animation */
  transform-origin: 50% 100%;
  /* Adds the crawl animation, which plays for one minute */
  animation: crawl 60s linear infinite;
}

@keyframes space {
  40% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.25;
  }
  60% {
    opacity: 0.75;
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes crawl {
  0% {
    /* The element starts below the screen */
    top: 0;
    /* Rotate the text 20 degrees but keep it close to the viewer */
    transform: rotateX(20deg) translateZ(0);
  }
  100% { 
    /* This is a magic number, but using a big one to make sure the text is fully off the screen at the end */
    top: -6000px;
    /* Slightly increasing the rotation at the end and moving the text far away from the viewer */
    transform: rotateX(25deg) translateZ(-2500px);
  }
}